import React from 'react'
import { motion, AnimatePresence } from 'framer-motion';

import { customiserPageVars } from '../../lib/animation';

const BuilderPageTransition = ({ className, children }) => {
  return (
    <AnimatePresence exitBeforeEnter>
        <motion.div
        className={className}
        variants={customiserPageVars}
        initial="exit"
        animate="enter"
        exit="exit"
        >{children}</motion.div>
    </AnimatePresence>
  )
}

export default BuilderPageTransition