import React from 'react'
import { motion, AnimatePresence } from 'framer-motion';

// Animation
import { customiserFocusColourVars } from '../../lib/animation';

const BuilderHoverColour = ({ hoverColour }) => {
  return (
    <AnimatePresence>
        <motion.div
            variants={customiserFocusColourVars}
            initial="exit"
            animate="enter"
            exit="exit"
            className="hidden lg:block absolute inset-0 w-full h-full z-10 pointer-events-none"
            key={hoverColour}
        >
            {hoverColour !== null && (
            <span className="block w-full h-full object-cover" style={{ background: hoverColour }}></span>
            )}
        </motion.div>
    </AnimatePresence>
  )
}

export default BuilderHoverColour