import React from 'react'
import { motion, AnimatePresence } from 'framer-motion';

import { PortableTextBlock } from "../ui/portableTextBlock"

// Animation
import { customiserFocusMediaVars } from '../../lib/animation';

const BuilderHoverDescription = ({ hoverDescription }) => {
  return (
    <AnimatePresence exitBeforeEnter>
        <motion.div                    
            variants={customiserFocusMediaVars}
            initial="exit"
            animate="enter"
            exit="exit"
            key={hoverDescription}
        >
            {hoverDescription !== null && (
              <PortableTextBlock 
                className="hidden lg:block absolute top-gutter right-gutter p-4 rounded-lg w-full max-w-xs z-20 bg-doc-bg prose"
                text={hoverDescription} 
              />
            )}
        </motion.div>
    </AnimatePresence>
  )
}

export default BuilderHoverDescription