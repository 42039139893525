import React, { useState, useEffect, useRef } from 'react'
import { Field } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'
import { motion, AnimatePresence } from 'framer-motion';

import BuilderPageTransition from './builderPageTransition'
import { customiserColours } from '../../lib/animation';
import BuilderError from './builderError'
import BuilderPantones from './builderPantones';
import BuilderBaseColours from './builderBaseColours';

const required = value => (value ? undefined : 'Required')

const BuilderPrinting = ({ 
    colourOptions, 
    printingOptions, 
    prodValues, 
    titlePrintingStage, 
    titlePrinting, 
    descPrinting, 
    titleColour, 
    descColour,    
    setHoverColour
 }) => {
    const [colourOption, setColourOption] = useState();
    const [colourLimit, setColourLimit] = useState(1);
    const [selectedColours, setSelectedColours] = useState(['']);    
    const coloursRef = useRef();

    useEffect(() => {    
        prodValues.colour !== null && setColourOption(prodValues.colour)        
        prodValues.colourItem !== null && setSelectedColours(prodValues.colourItem)        
      }, [setColourOption, setSelectedColours]);
      
    function coloursIntoView() {
        setTimeout(() => {
            coloursRef.current.scrollIntoView({ behavior: 'smooth' })
          }, 750);        
    }

  return (
    <BuilderPageTransition className={`builder_section`}>
        <p className="title">{titlePrintingStage && titlePrintingStage}</p>
        <p className="subtitle">{titlePrinting && titlePrinting}</p>
        <p className="desc">{descPrinting && descPrinting}</p>
        <ul className="cust-size">                        
            {printingOptions.map((item, index) => {
                return (
                <li key={index}>
                    <Field
                        type="checkbox"
                        name="printing"
                        component="input"
                        id={`printing-${index}`}
                        value={item}
                        validate={required}                                                
                    />
                    <label className="text-md lg:text-lg" htmlFor={`printing-${index}`}>{item}</label>                                
                </li>
            )                           
            })}                        
        </ul> 
        <BuilderError name="printing" />  
        {colourOptions && colourOptions[0] && (
        <>
        <div className="w-full my-12 border-t border-white/40"></div>
        <p className="subtitle">{titleColour && titleColour}</p>
        <p className="desc">{descColour && descColour}</p>
        <ul className="cust-size">                        
            {colourOptions.map((item, index) => {
                return (
                <li key={index}>
                    <Field
                        type="radio"
                        name="colour"
                        component="input"
                        validate={required}
                        id={`colour-${index}`}
                        value={`${item.title} - ${item.subtitle}`}
                        className="radio_pill"                        
                    />
                    <label htmlFor={`colour-${index}`}>
                        <span className="text-md lg:text-lg mr-2" >{item.title}</span>
                        {item.subtitle && <span className="text-xxs lg:text-base">{item.subtitle}</span>}
                    </label>                                
                </li>
            )                           
            })}                        
        </ul>   
        <BuilderError name="colour" /> 
        {/* <OnChange name="colour">
            {(value, previous) => {
              setColourOption(value)
              {value.includes('One') && setColourLimit(1)}
              {value.includes('Two') && setColourLimit(2)}
              {value.includes('Multiple') && setColourLimit(undefined)}              
              !previous && coloursIntoView(coloursIntoView)              
            }}
          </OnChange> */}
          {/* <div ref={coloursRef}>
          <AnimatePresence exitBeforeEnter>            
            {colourOption && (
                <motion.div             
                    className="mt-6 md:mt-12 grid grid-cols-5 grid-flow-row gap-8 items-start"                
                    initial="exit"
                    animate={"enter"}
                    variants={customiserColours}
                >   
                    <div className="col-span-full"><p>Choose your colours</p></div>
                    <div className="col-span-3">                                    
                        <BuilderPantones setHoverColour={setHoverColour} selectedColours={selectedColours} setSelectedColours={setSelectedColours} />
                    </div>
                    <div className="col-span-2 sticky top-4">
                        <p>
                            Selected colours
                            <span className="ml-1">
                                {colourLimit !== undefined && `(${selectedColours && selectedColours.length ? selectedColours.length : '0'}/${colourLimit})`}
                                {colourLimit === undefined && `(${selectedColours && selectedColours.length ? selectedColours.length : '0'})`}
                                </span>
                            </p>
                        <ul className="mt-8 flex flex-col">
                        {selectedColours && selectedColours.map((item, index) => {
                            return (
                                <li key={`selCol-${index}`} className="py-1 flex items-center">
                                    <div className="w-8 h-8 rounded-full border border-white mr-2" style={{ background: item }}></div>
                                    <p>Colour name</p>
                                </li>
                            )
                        })}
                        </ul>
                    </div>
                </motion.div>   
            )}         
            </AnimatePresence>
          </div>      */}
        </>
        )}
           
    </BuilderPageTransition>
  )
}

export default BuilderPrinting