import React, { useState } from 'react'
import { Field } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Mousewheel } from "swiper";

import "swiper/css/free-mode";
import 'swiper/css';

import BuilderPageTransition from './builderPageTransition'
import BuilderMaterialValidate from '../builder/builderMaterialValidate'
import BuilderError from './builderError'
import { PortableTextBlock } from '../ui/portableTextBlock'
  
const required = value => (value ? undefined : 'Required')

const BuilderMaterial = ({ 
  materials, 
  laminates,
  wovens, 
  setHoverImage, 
  setHoverDescription, 
  titleMaterial, 
  descMaterial,
  titleFinish,
  descFinish,
  finishes,
  materialTypes
 }) => {

    const [selectedMaterial, setSelectedMaterial] = useState() 
    const [showMaterialDesc, setShowMaterialDesc] = useState(false)    

    const Condition = ({ when, is, children }) => (
      <Field name={when} subscription={{ value: true }}>
          {({ input: { value } }) => (value === is ? children : null)}
      </Field>
      )


  return (
    <BuilderPageTransition className={`builder_section`}>
        
        <p className="title">{titleMaterial && titleMaterial}</p>
        
        <p className="desc">{descMaterial && descMaterial}</p>
        
        <Swiper                         
          spaceBetween={0}
          allowTouchMove={true}
          modules={[FreeMode, Mousewheel]}
          freeMode={true}
          mousewheel={true}
          slidesPerView={'auto'}    
          className="cust-material mb-6"
          breakpoints={{
            1200: {
              allowTouchMove:false,
              slidesPerView:'auto',
              spaceBetween: 0,
            },
          }}
        >      
            {materials.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                    <BuilderMaterialValidate {...item} {...{setHoverImage, setHoverDescription, index }} name={`material`} />                    
                </SwiperSlide>
            )                          
            })}                        
        </Swiper>      

         <Condition when="material" is="Specialty">
            <div className={`mt-6 flex flex-col md:flex-row md:items-baseline`}>            
            <div className={`md:w-1/2 flex flex-col`}>
            <label className="mb-1" htmlFor="specialty-spec">Specialty specifications</label>      
            <BuilderError name="specialtySpec" />
            </div>
            <Field
                type="text"
                name="specialtySpec"
                component="textarea"
                rows="3"
                className="input-specialty"
                id={`specialty-spec`}
                placeholder="Please enter your specifications for specialty material"     
                validate={required}                        
                
            />                            
            </div>
        </Condition>           
        
        {wovens && wovens.length > 0 && (
          <>
          <p className="mt-12 subtitle">Woven</p>
          <Swiper                         
            spaceBetween={0}
            allowTouchMove={true}
            modules={[FreeMode, Mousewheel]}
            freeMode={true}
            mousewheel={true}
            slidesPerView={'auto'}   
            className="cust-material mb-6"
            breakpoints={{
              1200: {
                allowTouchMove:false,
                slidesPerView:'auto',
                spaceBetween: 0,
              },
            }}
          >      
            {wovens.map((item, index) => {
              return (
                <SwiperSlide key={index}>                                                                    
                    <BuilderMaterialValidate {...item} {...{setHoverImage, setHoverDescription, index }} name={`material`} />                                        
                </SwiperSlide>
            )                          
            })}                        
            </Swiper>
        </>
        )}

        <BuilderError name="material" />

        <OnChange name="material">
          {(value) => {
            setSelectedMaterial(value)
          }}
        </OnChange>

        {materialTypes && materialTypes.length > 0 && (
          <ul className="cust-size">
          <p className="mt-12 subtitle">Material Types</p>
          {materialTypes.map((item, index) => {
            return (
            <li 
                key={index}                >
                <Field
                    type="radio"
                    name="materialType"
                    component="input"
                    id={`materialType-${item}`}
                    value={`${item}`}      
                    validate={required}                    
                />
                <label htmlFor={`materialType-${item}`}>
                    <span className="text-md lg:text-lg">{item}</span>
                </label>                                
            </li>
            )
        })} 
        </ul>
        )}       
        
        <BuilderError name="materialType" />
             

        <div className={`${(selectedMaterial !== null && selectedMaterial !== 'Uncoated brown') ? '' : 'opacity-50 pointer-events-none cursor-not-allowed'}`}>
        <Laminates {...{laminates, setHoverImage, setHoverDescription}}/>
        </div>
        
        
        <button 
          type="button" 
          onClick={()=>{setShowMaterialDesc(!showMaterialDesc)}} 
          className="w-full hidden justify-between items-baseline mt-6 py-4 border-b border-white/40 cursor-pointer xl:hover:opacity-50"
        >
          <span>Material Information</span>
            
            <svg width="14" height="6" viewBox="0 0 14 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L7 5L13 1" stroke="white"/>
            </svg>

        </button>
        
        {showMaterialDesc && (
            
            <div>
                {selectedMaterial && selectedMaterial.description && <PortableTextBlock className="text-white prose" text={selectedMaterial.description} />}
            </div>

        )}

        {finishes && finishes.length > 0 && (
            <>
            <p className="subtitle mt-12">{titleFinish && titleFinish}</p>
            <p className="desc">{descFinish && descFinish}</p>
            <ul className="cust-material small">                        
                {finishes.map((item, index) => {
                    return (
                    <li>
                        <BuilderMaterialValidate {...item} {...{setHoverImage, setHoverDescription, index, circle:'true' }} name={`finish`} />                                
                    </li>
                )                           
                })}                        
            </ul>   
            </>
        )}    

    </BuilderPageTransition>
  )
}

export default BuilderMaterial

const Laminates = ({ laminates, setHoverImage, setHoverDescription }) => {
  return (
    <>
    {laminates && laminates.length > 0 && (
          <>
          <p className="mt-12 subtitle">Select finish</p>
          <Swiper                         
            spaceBetween={0}
            allowTouchMove={false}
            modules={[FreeMode, Mousewheel]}
            freeMode={true}
            mousewheel={true}
            slidesPerView={'auto'}   
            className="cust-material mb-6"
          >    
            <SwiperSlide key={'none'}>                                                                    
                    <BuilderMaterialValidate {...{setHoverImage, setHoverDescription }} title="None" index={'none'} name={`laminate`} />                                        
              </SwiperSlide>  
            {laminates.map((item, index) => {
              return (
                <SwiperSlide key={index}>                                                                    
                    <BuilderMaterialValidate {...item} {...{setHoverImage, setHoverDescription, index }} name={`laminate`} />                                        
                </SwiperSlide>
            )                          
            })}                        
            </Swiper>
            <BuilderError name="laminate" />
        </>
        
        )}
    </>
  )
}