import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'
import { GatsbyImage } from "gatsby-plugin-image"
import { Field } from 'react-final-form'
import { Link } from "gatsby"

// Components
import BuilderNav from './builderNav';
import BuilderModal from './builderModal';
import BuilderHoverDescription from './builderHoverDescription'
import BuilderHoverSizeInfo from './builderHoverSizeInfo'
import BuilderSizeHoverImage from './builderSizeHoverImage'
import BuilderHoverImage from './builderHoverImage'
import BuilderHoverColour from './builderHoverColour'
import BuilderSummaryContent from './builderSummaryContent'
import BuilderFooter from './builderFooter';


export default class Wizard extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired
  }
  static Page = ({ children }) => children

  constructor(props) {
    super(props)
    this.state = {      
      page: props.wizardPage,
      values: props.initialValues || {},   
      submitted: false,   
    }
  }
  
  next = values => {    
    
    const { page } = this.state
    this.props.setProdValues(values)
    this.setState(state => ({
      page: Math.min(page + 1, this.props.children.length - 1),
      values
    }))
    window.scrollTo(0, 0)
    
  }
  
  previous = () => {
    
    const { page } = this.state
    this.setState(state => ({
      page: Math.max(page - 1, 0)
    }))
    window.scrollTo(0, 0)

  }  
  
  goTo = (target) => {
    
    const { page } = this.state
    this.setState(state => ({
      page: target
    }))
    window.scrollTo(0, 0)

  }
  

  closeModal = () => {
    this.setState(state => ({
      submitted:false
    }))
  }
  

  validate = values => {
    
    const activePage = React.Children.toArray(this.props.children)[
      this.state.page
    ]
    
    return activePage.props.validate ? activePage.props.validate(values) : {}

  }

  handleSubmit = values => {        
    const { children, onSubmit } = this.props
    const { page } = this.state    
    const isLastPage = page === React.Children.count(children) - 1    
    if (isLastPage) {
            
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'Builder Add to Cart'
      });

      if (localStorage.getItem("cart")) {
        var cartContent = JSON.parse(localStorage.getItem("cart") || []);
      } else {
        var cartContent = [];
      }
      cartContent.push(values);
      localStorage.setItem('cart', JSON.stringify(cartContent));
      this.setState(state => ({
        submitted:true
      }))
      // navigate(`/cart`)  
      // return onSubmit(values)
    } else {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'Builder Navigate',
        'navigate': `Page ${page + 2}`
      });
      this.next(values)
    }    
  }

  render() {
    const { children } = this.props
    const { page, values, submitted } = this.state
    const activePage = React.Children.toArray(children)[page]
    const isLastPage = page === React.Children.count(children) - 1

    return (
      <Form
        initialValues={values}
        validate={this.validate}
        onSubmit={this.handleSubmit}
      >
        {({ handleSubmit, submitting }) => (          

          <form onSubmit={handleSubmit}>            
            
            <div className="grid-std md:items-start md:grid-flow-row text-white">
              
              <div className="col-span-full md:col-span-3 lg:col-start-1 lg:col-end-14 lg:sticky relative lg:top-0 lg:h-screen">                            
                <BuilderSizeHoverImage hoverImage={this.props.sizeHoverImage} />
                <BuilderHoverImage hoverImage={this.props.hoverImage} />
                <BuilderHoverColour hoverColour={this.props.hoverColour} />
                <BuilderHoverDescription hoverDescription={this.props.hoverDescription} />
                <BuilderHoverSizeInfo text={this.props.hoverSizeInfo} />
                {this.props.imageHero && this.props.imageHero.asset && (
                    <GatsbyImage 
                        image={this.props.imageHero.asset.gatsbyImageData} 
                        alt={this.props.imageHero.asset.alt} 
                        className="w-full h-auto lg:h-screen object-cover" 
                    />                
                )}
              
              </div>
              
              <div className="col-span-full md:col-span-3 lg:col-start-14 lg:col-end-26 px-gutter md:px-0 lg:px-gutter lg:min-h-screen flex flex-col justify-start lg:overflow-auto pt-6 md:pt-0 pb-20 xl:pb-40">                              
                
                <BuilderNav page={page} />
                
                {activePage}
                
                {isLastPage && values != null && (
                  <>
                  <BuilderSummaryContent gotoPage={this.goTo} values={values} />
                  <div className="grid grid-cols-4 grid-flow-row">
                  <label className="block mt-4 mb-1" htmlFor="notes">Notes</label>                  
                  <Field
                    type="text"
                    name="notes"
                    component="textarea"
                    rows="3"
                    className="builder-notes col-span-3"
                    id={`notes`}
                    placeholder="Please add any additional details here"       
                    
                />
                  </div>  
                  </>
                )}
                              
              </div>     
            
            </div>                      
            
            <BuilderFooter 
              slug={this.props.slug.current}
              moq={this.props.moq}
              cartProject={this.props.cartProject} 
              prodPrice={this.props.prodPrice}
              fromPrice={this.props.fromPrice}
              submitting={submitting}
              previous={this.previous}              
              page={page}
              isLastPage={isLastPage}
            />
            <BuilderModal 
              item={this.props.prodValues}
              submitted={submitted} 
              relatedProducts={this.props.relatedProducts}
              imageHero={this.props.imageHero} 
              title={this.props.title} 
              closeModal={this.closeModal} 
            />           
          </form>                      

        )}
      </Form>
    )
  }
}
