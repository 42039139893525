import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { motion, AnimatePresence } from 'framer-motion';

// Hooks & Querys & Context
import { useSiteState } from '../../lib/siteContext';
import { useScrollFreeze } from '../../hooks';

// Animation
import { customiserSubmitModalVars } from '../../lib/animation';

const ModalInner = ({ submitted, item, imageHero, closeModal, title, relatedProducts }) => {

  const { 
    cartItems,
    cartProject,     
  } = useSiteState();  

  useScrollFreeze()

  return (
    <>
    <section>
      <div className="px-4 py-8 flex items-baseline justify-between">
        <div>
          <p className="text-lg">1 product added to your project</p>
          <p className="text-md mt-2">{cartProject !== null ? cartProject : ''}</p>
          <p className="text-md text-pp-grey70">
          {cartItems && cartItems.length > 0 ? (
            cartItems.length > 1 ? `${cartItems.length} items` : `${cartItems.length} item`
          ) : (
            ``
          )}
          </p>
        </div>
        <div>
          <button type="button" onClick={closeModal} className="hover:underline">Close</button>
        </div>
      </div>

      <div className="px-4 flex items-start">
      <div className="w-1/3 aspect-square relative">
        {imageHero && imageHero.asset && (
            
                <GatsbyImage 
                    image={imageHero.asset.gatsbyImageData} 
                    alt={imageHero.asset.alt} 
                    className="w-full h-full absolute inset-0 object-cover" 
                />             
          )}
          </div>               

          <div className="w-2/3 pb-4 px-gutter flex flex-col justify-between text-xs leading-tight">
            
            <div className="block text-md md:text-lg mb-2">{title && title}</div>
            <ul className="builder-modal-summary">
              {item.size && <li><span>Size:</span> {item.size}
              {item.size === 'Custom' && (
                <>
                <br/>
                {item.customWidth && item.customWidth}
                {` X `}
                {item.customLength && item.customLength}
                {` X `}
                {item.customHeight && item.customHeight}
                {` `}
                {item.customUnit && item.customUnit}
                </>
              )}
              </li>}
              {item.orientation && <li><span>Orientation:</span>{' '}{item.orientation}</li>}            
              {item.closure && <li><span>Closure:</span>{` `}
              {Array.isArray(item.closure) ? (
                item.closure.map((item, index) => {
                  return (
                    <>{item}{` `}</>
                  )
                })
              ) : (
                <>{item.closure}</>
              )}            
              </li>}
              
              {item.material && <li><span>Material:</span> {item.material}</li>}
              {item.materialType && <li><span>Material Type:</span> {item.materialType}</li>}
              {item.laminate && item.laminate !== 'None' && <li><span>Laminate:</span> {item.laminate}</li>}
              {item.printing && <li><span>Printing:</span> {item.printing}</li>}
              {item.colour && <li><span>Colour:</span> {item.colour}</li>}
              {item.embossing && <li><span>Emboss:</span> {item.embossing}</li>}
              {item.foil && <li><span>Foil:</span> {item.foil}</li>}
              {item.finish && <li><span>Finish:</span> {item.finish}</li>}
              {item.feature && <li><span>Feature:</span> {item.feature}</li>}
              {item.quantity && <li><span>Quantity:</span> {item.quantity}{item.quantityCustom && ` - ${item.quantityCustom}`}</li>} 
            </ul>           

        </div>     
      </div>   

      {relatedProducts && (
        <>
        <div className="px-4 mt-12 lg:mt-20 mb-4 flex items-baseline justify-between">
          <p>Items you may like</p>
          <Link to="/packaging">View all</Link>
        </div>

        <div className="flex snap-x overflow-x-auto pb-4">
          {relatedProducts.map((product, index) => {
            return (
              <Link to={`/builder/${product.slug.current}`} className="pl-4 w-2/5 lg:w-1/3 snap-start shrink-0">
                  <GatsbyImage 
                    image={product.imageHero.asset.gatsbyImageData} 
                    alt={product.title} 
                    className="w-full aspect-square object-cover mb-2"
                />                                   
                <p>{product.title}</p>
              </Link>
            )
          })}
        </div>
        </>
      )}


    </section> 

    <div className="flex justify-end px-4 pb-4 mt-6">
      <Link to={'/packaging'} className="btn-rnd flex items-center mr-4">Continue browsing</Link>
      <Link to={'/cart'} className="flex btn-rnd-white">View my project</Link>
    </div>   
    </>
  )
}
const BuilderModal = ({ submitted, item, imageHero, closeModal, title, relatedProducts }) => {

  return (
    <AnimatePresence exitBeforeEnter>                
      
    {submitted && (
      
      <motion.div 
        className="flex flex-col justify-between z-3000 fixed top-0 bottom-0 left-0 right-0 bg-doc-bg md:border-l md:border-white/50 md:left-unset md:w-full md:max-w-xl overflow-y-scroll"
        variants={customiserSubmitModalVars}
        initial="exit"
        animate="enter"
        exit="exit"
      >
        
        <ModalInner {...{submitted, item, imageHero, closeModal, title, relatedProducts}} />                             

      </motion.div>
    
    )}
      
    </AnimatePresence>  
  )
}

export default BuilderModal