import React from 'react'

import BuilderPageTransition from './builderPageTransition'

const BuilderSummary = ({ prodValues, setWizardPage, titleSummary, descSummary }) => {
  return (
    <BuilderPageTransition className={`builder_section`}>
        <p className="title">{titleSummary && titleSummary}</p>
        <p className="desc mb-12">{descSummary && descSummary}</p>        
    </BuilderPageTransition>
  )
}

export default BuilderSummary