import React from 'react'
import { Field } from 'react-final-form'
import { GatsbyImage } from "gatsby-plugin-image"
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Mousewheel } from "swiper";

import "swiper/css/free-mode";
import 'swiper/css';


import BuilderPageTransition from './builderPageTransition'
import BuilderError from './builderError'

const required = value => (value ? undefined : 'Required')

const ClosureItem = ({ 
  name, 
  item, 
  setHoverImage, 
  circle = false, 
  index 
}) => {
  return (
    <button
      type="button" 
      className="relative w-full"
      onFocus={() => setHoverImage(item.illustration ? item.illustration : null)} 
      onMouseOver={() => setHoverImage(item.illustration ? item.illustration : null)} 
      onMouseOut={() => setHoverImage(null)} 
      onBlur={() => setHoverImage(null)} 
      >                               

      <Field
          type="checkbox"
          name={name}
          component="input"
          id={`${item.name.replace(/ /g, '-')}${index}`}
          value={item.name}    
          validate={required}                       
      />
      
      <label 
        htmlFor={`${item.name.replace(/ /g, '-')}${index}`}
        className="cursor-pointer"                    
      >
      
        <div className="text-base group">
          {item.illustration && item.illustration.asset ? (
            <div className={`card-media`}>
                <GatsbyImage      
                  className={`w-full aspect-square object-cover img-rounded overflow-hidden ${circle ? 'rounded-full' : 'rounded-lg'}`}
                  image={item.illustration.asset.gatsbyImageData} 
                  alt={item.name}                 
                />    
            </div>
          ) : (
              <div className={`card-media w-full aspect-square object-cover bg-pp-grey30 ${circle ? 'rounded-full' : 'rounded-lg'}`}></div>
          )}
          
          <div className={`mt-2 text-sm flex ${circle ? 'text-center' : ''}`}>
            
            <span>{item.name && item.name}</span>                        

          </div>                                   

        </div> 
      
      </label>                                

    </button>    
  )
}
const BuilderClosure = ({ 
  closureOptions, 
  titleClosure, 
  setHoverImage, 
  descClosure 
}) => {
  return (
    <BuilderPageTransition className={`builder_section`}>
      
      <p className="title">{titleClosure && titleClosure}</p>
      
      <p className="desc">{descClosure && descClosure}</p>
      <Swiper                         
        spaceBetween={0}
        allowTouchMove={true}
        modules={[FreeMode, Mousewheel]}
        freeMode={true}
        mousewheel={true}
        slidesPerView={'auto'}    
        className="cust-material"
        breakpoints={{
          1200: {
            allowTouchMove:false,
            slidesPerView:'auto',
            spaceBetween: 0,
          },
        }}
      >          
          {closureOptions.map((item, index) => {            
              return (
              <SwiperSlide key={item.name.replace(/ /g, '-')}>               
                <ClosureItem 
                  setHoverImage={setHoverImage} 
                  name={`closure`} 
                  index={index}                   
                  item={item}                                     
                />                      
              </SwiperSlide>
              )
          })}                        
      </Swiper>

      <div className="mt-2"><BuilderError name="closure" />    </div>

    </BuilderPageTransition>
  )
}

export default BuilderClosure