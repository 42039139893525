import React, { useState, useEffect } from 'react'
import { Field } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'

import BuilderPageTransition from './builderPageTransition'
import BuilderError from './builderError'

const required = value => (value ? undefined : 'Required')
const minimum = value => (value > 1499 ? undefined : 'Minimum of 1500')

const BuilderQuantities = ({ prodValues, titleQuantity, descQuantity, moq, quantities }) => {

    const [isCustom, setIsCustom] = useState(false)

    useEffect(() => {    
        prodValues.quantity === 'Custom' && setIsCustom(true)        
      }, []);

    const Condition = ({ when, is, children }) => (
    <Field name={when} subscription={{ value: true }}>
        {({ input: { value } }) => (value === is ? children : null)}
    </Field>
    )

  return (
    <BuilderPageTransition className={`builder_section`}>
    <p className="title">{titleQuantity && titleQuantity}</p>
    <p className="desc">{descQuantity && descQuantity}</p>
    <ul className="cust-qty two-col">
        {quantities.map((quantity) => {
            const id = quantity.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'')
            return (
                <li key={id}>            
                    <label htmlFor={id}>
                    <Field
                        type="radio"
                        name="quantity"
                        component="input"
                        id={id}
                        value={quantity}
                        validate={required}
                        
                    />
                    <span>{quantity}</span>
                </label>                                
                </li>   
            )
        })}                
        <li>            
            <label htmlFor="quantity-custom">
            <Field
                type="radio"
                name="quantity"
                component="input"
                id={`quantity-custom`}
                value={'Custom'}
                validate={required}
                
            />
            <span>Custom</span>
            </label>                  
        </li>
        <Condition when="quantity" is="Custom">
            <li className={`input-custom`}>            
            <label className={`hidden`} htmlFor="quantity-custom-value">Custom</label>      
            <Field
                type="number"
                name="quantityCustom"
                component="input"
                className="rounded-md"
                id={`quantity-custom-value`}
                placeholder="Enter your custom quantity.."     
                validate={minimum}                        
                
            />    
            {moq && <p className="mt-2 text-xs"><div className="inline-flex opacity-60 text-xs">Minimum Order Quantity:</div> {moq}</p>}
            <BuilderError name="quantityCustom" />
            </li>
        </Condition>
        
        <OnChange name="quantity">
          {(value) => {
            if (value === 'Custom') {
                setIsCustom(true)
            } else (
                setIsCustom(false)
            )
          }}
        </OnChange>
    </ul>    
    <BuilderError name="quantity" />  
</BuilderPageTransition>
  )
}

export default BuilderQuantities