import React from 'react'
import { motion, AnimatePresence } from 'framer-motion';

// Animation
import { customiserFocusMediaVars } from '../../lib/animation';

const BuilderHoverSizeInfo = ({ text }) => {
  return (
    <AnimatePresence exitBeforeEnter>
        {text !== null && (            
        <motion.div                    
            className="hidden lg:block absolute top-gutter right-gutter p-3 rounded-lg z-20 bg-black/50 text-sm"
            variants={customiserFocusMediaVars}
            initial="exit"
            animate="enter"
            exit="exit"
            key={text}
        >       
            <p className="opacity-70 mb-1">Suitable for</p>
            <p>{text}</p>                 
        </motion.div>
        )}        
    </AnimatePresence>
  )
}

export default BuilderHoverSizeInfo