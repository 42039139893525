import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { Helmet } from "react-helmet"

// Hooks & Querys & Context
import { useSiteState } from '../lib/siteContext';
import { QueryFragments } from '../GraphQl/queryFragments'; // eslint-disable-line
import { useBuilder } from '../GraphQl/useBuilder';

// Components
import Layout from '../components/layout';
import Seo from '../components/global/seo';
import Wizard from '../components/builder/wizard'
import BuilderSize from '../components/builder/builderSize'
import BuilderClosure from '../components/builder/builderClosure'
import BuilderMaterial from '../components/builder/builderMaterial'
import BuilderPrinting from '../components/builder/builderPrinting'
import BuilderAdditions from '../components/builder/builderAdditions'
import BuilderQuantities from '../components/builder/builderQuantities';
// import BuilderDielines from '../components/builder/builderDielines';
import BuilderSummary from '../components/builder/builderSummary';

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

const onSubmit = async values => {
  await sleep(300)
  window.alert(JSON.stringify(values, 0, 2))
}

const PackagingProductBuilder = ({ data: { sanityProduct } }) => {

    const { 
      titleSize,
      titleDesc,
      titleOrientation,
      descOrientation,
      titleClosure,
      descClosure,
      titleMaterial,
      descMaterial,
      titlePrintingStage,
      titlePrinting,
      descPrinting,
      titleColour,
      descColour,
      titleAdditions,
      titleEmbossing,
      descEmbossing,
      titleFoils,
      descFoils,
      infoFoils,
      titleTextures,
      descTextures,
      infoTextures,
      titleFinish,
      descFinish,
      titleFeatures,
      descFeatures,
      titleQuantity,
      descQuantity,
      titleDielines,
      descDielines,
      titleSummary,
      descSummary
     } = useBuilder()

    const { 
      title,
      seo,
      leadTime, 
      moq,
      imageHero,
      materials,
      laminates,
      wovens,
      textures,
      foils,
      embossDeboss,
      sizes,
      orientations,
      printingOptions,
      colourOptions,      
      closureOptions,
      features,
      finishes,      
      basePrice,
      fromPrice,
      quantities,
      slug,
      relatedProducts,
      materialTypes
    } = sanityProduct || {}  

    const { 
      setShowingBriefUs, 
      setFooterHidden, 
      setPageFeatureColor, 
      setPageBackground, 
      setMyPorter, 
      setHeaderDark, 
      setFooterForms,
      cartProject,
      cartEditItem,
      setCartEditItem,
      setShowAuthModal
    } = useSiteState();

    const initialValues = {
      product: title,
      size:sizes && sizes[0] ? sizes[0].size : '', 
      leadTime: 
      leadTime, 
      slug: slug, 
      laminate: 'None',
      image: imageHero
    }

    const [sizeHoverImage, setSizeHoverImage] = useState(null)  
    const [hoverImage, setHoverImage] = useState(null)  
    const [hoverColour, setHoverColour] = useState(null)  
    const [hoverDescription, setHoverDescription] = useState(null)  
    const [hoverSizeInfo, setHoverSizeInfo] = useState(null)  
    const [prodValues, setProdValues] = useState(null)               
    const [wizardPage, setWizardPage] = useState(cartEditItem ? 6 : 0)      
    const [costSize, setCostSize] = useState(sizes && sizes[0] ? sizes[0].sizePrice : 0)  
    const [prodPrice, setProdPrice] = useState(costSize)  

    useEffect(() => {    
      document.documentElement.setAttribute('data-theme', `theme-black`);
      document.documentElement.style.setProperty(`--doc-bg`, '#141414');          
      setShowAuthModal(false)
      setMyPorter(false)
      setHeaderDark(false)
      setFooterForms(false)
      setPageFeatureColor(null)
      setShowingBriefUs(true)
      setFooterHidden(true)
      if (cartEditItem !== null) {
        setProdValues(cartEditItem)
        setCartEditItem(null)
      }
    }, [setPageBackground, setShowAuthModal, setMyPorter, setHeaderDark, setPageFeatureColor, setFooterForms, setShowingBriefUs, setFooterHidden, setCartEditItem, cartEditItem]);            
  
  return (
    <Layout>     
      {seo && <Seo {...seo} />}        

      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      </Helmet>

      <Wizard       
        initialValues={cartEditItem !== null ? cartEditItem : initialValues}
        {...{
          hoverImage, 
          sizeHoverImage,           
          hoverColour,
          hoverDescription, 
          hoverSizeInfo,
          cartProject, 
          imageHero, 
          slug, 
          basePrice, 
          fromPrice, 
          title, 
          prodValues, 
          setProdValues, 
          wizardPage, 
          setWizardPage, 
          prodPrice,
          relatedProducts,
          moq
        }}        
        onSubmit={onSubmit}
      > 
             
        <Wizard.Page>
          <BuilderSize             
            {...{
              sizes, 
              orientations, 
              prodValues, 
              setCostSize, 
              titleSize, 
              titleDesc, 
              titleOrientation, 
              descOrientation,
              setHoverImage,
              setSizeHoverImage, 
              setHoverSizeInfo
            }}
          />
        </Wizard.Page>          
        
        <Wizard.Page>
          <BuilderClosure           
          {...{
            setHoverImage,
            closureOptions, 
            titleClosure, 
            descClosure
          }}
        />
        </Wizard.Page>
        
        <Wizard.Page>
          <BuilderMaterial             
            {...{
              setHoverDescription, 
              setHoverImage, 
              materials, 
              laminates,
              wovens, 
              titleMaterial, 
              descMaterial,
              titleFinish,
              descFinish,
              finishes,
              materialTypes
            }}
          />
        </Wizard.Page>
        
        <Wizard.Page>
          <BuilderPrinting             
            {...{
              prodValues, 
              printingOptions, 
              colourOptions, 
              titlePrintingStage, 
              titlePrinting, 
              descPrinting, 
              titleColour, 
              descColour, 
              hoverColour, 
              setHoverColour
            }}
          />
        </Wizard.Page>
        
        <Wizard.Page>
          <BuilderAdditions                          
            {...{
              setHoverDescription,
              setHoverImage,
              textures, 
              foils, 
              features,               
              titleAdditions,
              titleEmbossing,
              descEmbossing,
              embossDeboss,
              titleFoils,
              descFoils,
              infoFoils,
              titleTextures,
              descTextures,              
              infoTextures,
              titleFeatures,
              descFeatures,
            }}
          />
        </Wizard.Page>
        
        <Wizard.Page>
          <BuilderQuantities             
            {...{
              prodValues, 
              titleQuantity, 
              descQuantity,
              moq,
              quantities
            }}
          />
        </Wizard.Page>
        
        {/* <Wizard.Page>
          <BuilderDielines 
            {...{
              titleDielines, 
              descDielines
            }}
          />
        </Wizard.Page> */}
        
        <Wizard.Page>
          <BuilderSummary             
            {...{
              prodValues, 
              setWizardPage, 
              titleSummary, 
              descSummary
            }}            
          />
        </Wizard.Page>                                                                                
        
      </Wizard>                          
    </Layout>
  )
}

export default PackagingProductBuilder

export const pageQuery = graphql`
  query productBuilderQuery($slug: String!) {
    sanityProduct(slug: { current: { eq: $slug } }) {
        title
        slug {
          current
        }        
        moq: quantity,
        quantities
        leadTime
        basePrice
        fromPrice
        imageHero {
            asset {
                gatsbyImageData
            }
        }                
        seo {
            ...SEO
        }       
        orientations
        printingOptions
        relatedProducts {
          title
          slug {
            current
          }
          imageHero {
            asset {
                gatsbyImageData
            }
          }   
        }
        sizes {
          size
          sizeInfo
          sizePrice
          sizeSuitableFor
          sizeImage {
            asset {
                gatsbyImageData
            }
          }   
        }
        colourOptions {
          title
          subtitle
        }
        sustainabilityOptions {
          title
          icon
        }
        certificationOptions {
          title
          icon
        }
        closureOptions {
          name
          illustration {
            asset {
                gatsbyImageData
            }
          }
        }
        materials {
          title
          description: _rawDescription(resolveReferences: {maxDepth: 10})
          imagePrimary {
            asset {
                gatsbyImageData
            }
          }
        }
        materialTypes,
        laminates {
          title
          description: _rawDescription(resolveReferences: {maxDepth: 10})
          imagePrimary {
            asset {
                gatsbyImageData
            }
          }
        }
        wovens {
          title
          description: _rawDescription(resolveReferences: {maxDepth: 10})
          imagePrimary {
            asset {
                gatsbyImageData
            }
          }
        }
        textures {
          id
            title
            description: _rawDescription(resolveReferences: {maxDepth: 10})
            imagePrimary {
              asset {
                  gatsbyImageData
              }
            }
        }
        foils {
          id
            title
            description: _rawDescription(resolveReferences: {maxDepth: 10})
            imagePrimary {
              asset {
                  gatsbyImageData
              }
            }
        }
        embossDeboss {
          id
            title
            description: _rawDescription(resolveReferences: {maxDepth: 10})
            imagePrimary: image {
              asset {
                  gatsbyImageData
              }
            }
        }
        finishes {
          title
          description: _rawDescription(resolveReferences: {maxDepth: 10})
          imagePrimary {
            asset {
                gatsbyImageData
            }
          }
        }
        features {
          title
          description: _rawDescription(resolveReferences: {maxDepth: 10})
          imagePrimary {
            asset {
                gatsbyImageData
            }
          }
        }
    }
  }
`;
 