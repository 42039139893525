import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image"
import { motion, AnimatePresence } from 'framer-motion';

// Animation
import { customiserFocusMediaVars } from '../../lib/animation';

const BuilderSizeHoverImage = ({ hoverImage }) => {
  return (
    <AnimatePresence exitBeforeEnter>
        <motion.div
            variants={customiserFocusMediaVars}
            initial="exit"
            animate="enter"
            exit="exit"
            className="hidden lg:block absolute inset-0 lg:top-0 w-full h-full z-10"
            key={hoverImage}
        >
            {hoverImage !== null && (
            <GatsbyImage 
                image={hoverImage.asset.gatsbyImageData} 
                alt={'Hover image'} 
                className="w-full h-full object-cover" 
            />
            )}
        </motion.div>
    </AnimatePresence>
  )
}

export default BuilderSizeHoverImage