import React, { useState, useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Mousewheel } from "swiper";
import { Field } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'

import "swiper/css/free-mode";
import 'swiper/css';

import BuilderPageTransition from './builderPageTransition'
import BuilderMaterialCard from '../builder/builderMaterialCard'

const BuilderAdditions = ({ 
    textures, 
    foils, 
    features, 
    setHoverImage, 
    setHoverDescription,
    titleAdditions,
    titleEmbossing,
    descEmbossing,
    embossDeboss,
    titleFoils,
    descFoils,
    infoFoils,
    titleTextures,
    descTextures,
    infoTextures,
    titleFeatures,
    descFeatures
 }) => {

    const [foilNone, setFoilNone] = useState(undefined)
    const [textureNone, setTextureNone] = useState(undefined)
    const [featureNone, setFeatureNone] = useState(undefined)
    const [embossNone, setEmbossNone] = useState(undefined)
  return (
    <BuilderPageTransition className={`builder_section`}>
        <p className="title">{titleAdditions && titleAdditions}</p>
        {embossDeboss && embossDeboss.length > 0 && (
            <>
            <p className="subtitle inline-flex items-baseline">
                {titleEmbossing && titleEmbossing}
                <label 
                  htmlFor={`embosssing-none`}
                  className={`ml-2 text-sm cursor-pointer hover:underline ${(embossNone !== undefined && embossNone !== 'None') ? 'flex' : 'hidden'}`}                    
                  >
                    <Field
                    type="radio"
                    name={'embossing'}
                    value="None"
                    id={'embosssing-none'}
                    component="input"                                        
                    className="hidden"
                />
                Reset
                </label>
                </p>
            <p className="desc">{descEmbossing && descEmbossing}</p>
            <Swiper                         
                spaceBetween={0}
                allowTouchMove={true}
                modules={[FreeMode, Mousewheel]}
                freeMode={true}
                mousewheel={true}
                slidesPerView={'auto'}   
                className="cust-material small"
                breakpoints={{
                    1200: {
                      allowTouchMove:false,
                      slidesPerView:'auto',
                      spaceBetween: 0,
                    },
                  }}
            >      
                {embossDeboss && embossDeboss.map((item, index) => {
                    return (
                    <SwiperSlide>
                        <BuilderMaterialCard {...item} {...{setHoverImage, setHoverDescription }} circle={true} index={index} name={'embossing'} />                                                         
                    </SwiperSlide>            
                    )
                })}                        
            </Swiper>
            <OnChange name="embossing">                
            {(value) => {                      
                setEmbossNone(value)                
            }}
            </OnChange>
            </>
        )}
        {foils && foils.length > 0 && (
            <>
            <p className="subtitle mt-12 inline-flex items-center">
                {titleFoils && titleFoils}
                {foils && infoFoils && (
                    <div
                        className={`hidden xl:block ml-2 fill-white`}
                        onMouseOver={() => setHoverDescription(infoFoils ? infoFoils : null)} 
                        onMouseOut={() => setHoverDescription(null)} 
                    >
                        <svg 
                            className={`cursor-help w-4 h-4 trans-std`} 
                            xmlns="http://www.w3.org/2000/svg" 
                            width="24" 
                            height="24" 
                            viewBox="0 0 24 24"
                        >
                            <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm1 18h-2v-8h2v8zm-1-12.25c.69 0 1.25.56 1.25 1.25s-.56 1.25-1.25 1.25-1.25-.56-1.25-1.25.56-1.25 1.25-1.25z"/>
                        </svg>
                    </div>
                )}
                <label 
                  htmlFor={`foil-none`}
                  className={`ml-2 text-sm cursor-pointer hover:underline ${(foilNone !== undefined && foilNone !== 'None') ? 'flex' : 'hidden'}`}                    
                  >
                    <Field
                    type="radio"
                    name={'foil'}
                    value="None"
                    id={'foil-none'}
                    component="input"                                        
                    className="hidden"
                />
                Reset
                </label>
            </p>
            <p className="desc">
                {descFoils && descFoils}                
            </p>
            <Swiper                         
                spaceBetween={0}
                allowTouchMove={true}
                modules={[FreeMode, Mousewheel]}
                freeMode={true}                
                mousewheel={true}
                slidesPerView={'auto'}   
                className="cust-material small"
                breakpoints={{
                    1200: {
                      allowTouchMove:false,
                      slidesPerView:'auto',
                      spaceBetween: 0,
                    },
                  }}
            >                      
                {foils.map((item, index) => {
                    return (
                    <SwiperSlide key={index}>
                        <BuilderMaterialCard {...item} showDesc={false} {...{setHoverImage, setHoverDescription, index }} name={`foil`} />                    
                    </SwiperSlide>
                )                         
                })}                        
            </Swiper>
            <OnChange name="foil">                
            {(value) => {            
                if (value === 'None') {                
                    setFoilNone(value)                
                } else (
                    setFoilNone(value)
                )
            }}
            </OnChange>
            </>
        )}
        {textures && textures.length > 0 && (
            <>
            <p className="subtitle mt-12 relative inline-flex items-center">
                {titleTextures && titleTextures}
                {textures && infoTextures && (
                    <div
                        className={`hidden xl:block ml-2 fill-white`}
                        onMouseOver={() => setHoverDescription(infoTextures ? infoTextures : null)} 
                        onMouseOut={() => setHoverDescription(null)} 
                    >
                        <svg 
                            className={`cursor-help w-4 h-4 trans-std`} 
                            xmlns="http://www.w3.org/2000/svg" 
                            width="24" 
                            height="24" 
                            viewBox="0 0 24 24"
                        >
                            <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm1 18h-2v-8h2v8zm-1-12.25c.69 0 1.25.56 1.25 1.25s-.56 1.25-1.25 1.25-1.25-.56-1.25-1.25.56-1.25 1.25-1.25z"/>
                        </svg>
                    </div>
                )}                 
              <label 
                  htmlFor={`texture-none`}
                  className={`ml-2 text-sm cursor-pointer hover:underline ${(textureNone !== undefined && textureNone !== 'None') ? 'flex' : 'hidden'}`}                    
                  >
                    <Field
                    type="radio"
                    name={'texture'}
                    value="None"
                    id={'texture-none'}
                    component="input"                                        
                    className="hidden"
                />
                Reset
                </label>
            </p>
            <p className="desc">{descTextures && descTextures}</p>
            <Swiper                         
                spaceBetween={0}
                allowTouchMove={true}
                modules={[FreeMode, Mousewheel]}
                freeMode={true}
                mousewheel={true}
                slidesPerView={'auto'}   
                className="cust-material small"
                breakpoints={{
                    1200: {
                      allowTouchMove:false,
                      slidesPerView:'auto',
                      spaceBetween: 0,
                    },
                  }}
            >                             
                {textures.map((item, index) => {
                    return (
                    <SwiperSlide>
                        <BuilderMaterialCard {...item} showDesc={false} {...{setHoverImage, setHoverDescription, index }} name={`texture`} />                                                
                    </SwiperSlide>
                )                         
                })}                        
            </Swiper> 
            <OnChange name="texture">                
            {(value) => {            
                if (value === 'None') {                
                    setTextureNone(value)                
                } else (
                    setTextureNone(value)
                )
            }}
            </OnChange>
            </>
        )}
             
        {features && features.length > 0 && (
            <>
            <p className="subtitle mt-12 inline-flex items-center">
                {titleFeatures && titleFeatures}
                <label 
                  htmlFor={`feature-none`}
                  className={`ml-2 text-sm cursor-pointer hover:underline ${(featureNone !== undefined && featureNone !== 'None') ? 'flex' : 'hidden'}`}                    
                  >
                    <Field
                    type="radio"
                    name={'feature'}
                    value="None"
                    id={'feature-none'}
                    component="input"                                        
                    className="hidden"
                />
                Reset
                </label>
                </p>
            <p className="desc">{descFeatures && descFeatures}</p>
            <Swiper                         
                spaceBetween={0}
                allowTouchMove={true}
                modules={[FreeMode, Mousewheel]}
                freeMode={true}
                mousewheel={true}
                slidesPerView={'auto'}   
                className="cust-material small"
                breakpoints={{
                    1200: {
                      allowTouchMove:false,
                      slidesPerView:'auto',
                      spaceBetween: 0,
                    },
                  }}
            >                                        
                {features.map((item, index) => {
                    return (
                    <SwiperSlide>
                        <BuilderMaterialCard {...item} {...{setHoverImage, setHoverDescription, index }} name={`feature`} />                                                
                    </SwiperSlide>
                )                           
                })}                        
            </Swiper>   
            <OnChange name="feature">                
            {(value) => {                     
                setFeatureNone(value)
            }}
            </OnChange>
            </>
        )}
    </BuilderPageTransition>
  )
}

export default BuilderAdditions