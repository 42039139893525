import React from 'react'

const BuilderNav = ({ page }) => {
  return (
    <ul className="cust_step_nav">
            <li className={`${page >= 0 ? 'current' : ''} ${page >= 1 ? 'active' : ''}`}>                
                <span className="w-0"><div></div></span>                
                <p className="absolute left-0 top-full">Size</p>
            </li>
            <li className={`${page >= 1 ? 'current' : ''} ${page >= 2 ? 'active' : ''}`}>
                <span><div></div></span>                
                <p>Closure</p>
            </li>
            <li className={`${page >= 2 ? 'current' : ''} ${page >= 3 ? 'active' : ''}`}>
                <span><div></div></span>                
                <p>Material</p>
            </li>
            <li className={`${page >= 3 ? 'current' : ''} ${page >= 4 ? 'active' : ''}`}>
                <span><div></div></span>                
                <div></div>
                <p>Printing</p>
            </li>
            <li className={`${page >= 4 ? 'current' : ''} ${page >= 5 ? 'active' : ''}`}>
                <span><div></div></span>                
                <p>Additions</p>
            </li>
            <li className={`${page >= 5 ? 'current' : ''} ${page >= 6 ? 'active' : ''}`}>
                <span><div></div></span>                
                <p>Quantity</p>
            </li>           
            {/* <li className={`${page >= 7 ? 'active' : ''}`}>
                <span><div></div></span>                
                <p>Dielines</p>
            </li> */}
            <li className={`${page >= 6 ? 'current' : ''} ${page >= 7 ? 'active' : ''}`}>                
                <span><div></div></span>                
                <p>Summary</p>
            </li>
        </ul>
  )
}

export default BuilderNav