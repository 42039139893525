import React, { useState, useEffect } from 'react'
import { Field } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'
import createDecorator from 'final-form-calculate'

import BuilderPageTransition from './builderPageTransition'
import BuilderError from './builderError'

const required = value => (value ? undefined : 'Required')

const BuilderSize = ({ setHoverImage, setSizeHoverImage, setHoverSizeInfo, sizes, orientations, prodValues, setCostSize, titleSize, titleDesc, titleOrientation, descOrientation }) => {

    const [isCustom, setIsCustom] = useState(false)            
    

    useEffect(() => {    
        prodValues && prodValues.size === 'Custom' && setIsCustom(true)        
      }, [prodValues]);      

    function handleMouseOver (item) {
        setHoverImage(item.sizeImage ? item.sizeImage : null)
        setHoverSizeInfo(item.sizeSuitableFor ? item.sizeSuitableFor : null)        
    }

    function handleMouseOut () {
        setHoverImage(null)
        setHoverSizeInfo(null)        
    }

    const Condition = ({ when, is, children }) => (
        <Field name={when} subscription={{ value: true }}>
          {({ input: { value } }) => (value === is ? children : null)}
        </Field>
      )

  return (
    <BuilderPageTransition className={`builder_section`}>
        <p className="title">{titleSize && titleSize}</p>
        <p className="desc">{titleDesc && titleDesc}</p>
        <ul className="cust-size">            
        {sizes && sizes.map((item, index) => {
            return (
            <li 
                key={index}
                onMouseOver={() => handleMouseOver(item)} 
                onMouseOut={() => handleMouseOut()} 
            >
                <Field
                    type="radio"
                    name="size"
                    component="input"
                    id={`size-${item.size}`}
                    value={`${item.size}${item.size !== 'Custom' ? ` - ${item.sizeInfo}` : ``}`}      
                    validate={required}       
                    onClick={()=>{setSizeHoverImage(item.sizeImage ? item.sizeImage : null)}}
                />
                <label htmlFor={`size-${item.size}`}>
                    {item.size && <span className="text-md lg:text-lg mr-2">{item.size}</span>}
                    {item.sizeInfo && <span className="text-xxs lg:text-base">{item.sizeInfo}</span>}
                </label>                                
            </li>
            )
        })} 
        {/* Removed 5/12/22 by liam from li */}
        {/* ${isCustom ? '' : 'opacity-0 pointer-events-none'} */}
            <li className={`input-custom mt-2 `}>                          
                <Condition when="size" is="Custom">
                    <div className="flex items-center mb-4">
                        <div className="relative">
                            <span className="absolute left-0 top-1/2 transform -translate-y-1/2 text-sm">W</span>
                            <Field
                                name="customWidth"
                                component="input"          
                                validate={required}              
                            />  
                            <div className="absolute left-0 top-full mt-1"><BuilderError name="customWidth" /></div>
                        </div>                    
                        <div className="mx-2 opacity-50 text-xs">x</div>
                        <div className="relative">
                            <span className="absolute left-0 top-1/2 transform -translate-y-1/2 text-sm">L</span>
                            <Field
                                name="customLength"
                                component="input"
                                validate={required}
                            />    
                            <div className="absolute left-0 top-full mt-1"><BuilderError name="customLength" /></div>
                        </div>                                            
                        <div className="mx-2 opacity-50 text-xs">x</div> 
                        <div className="relative">
                            <span className="absolute left-0 top-1/2 transform -translate-y-1/2 text-sm">H</span>
                            <Field
                                name="customHeight"
                                component="input"    
                                validate={required}                    
                            />  
                            <div className="absolute left-0 top-full mt-1"><BuilderError name="customHeight" /></div>
                        </div>                                            
                        <Field
                            name="customUnit"
                            component="select"
                            initialValue="mm"
                            validate={required}
                        >
                            <option value="mm">mm</option>
                            <option value="cm">cm</option>
                            <option value="inches">inches</option>
                        </Field>                       
                    </div>                   
                </Condition>                          
            </li>                       
        </ul>
        <OnChange name="size">
          {(value) => {
            if (value === 'Custom') {                
                setIsCustom(true)                
            } else (
                setIsCustom(false)
            )
          }}
        </OnChange>
        <BuilderError name="size" />        
        {orientations && orientations.length > 0 && (
        <section className="border-t border-white/40">
            <p className="title mt-6">{titleOrientation && titleOrientation}</p>
            <p className="desc">{descOrientation && descOrientation}</p>
            <ul className="cust-size">                
            {orientations.map((item, index) => {
                return (
                <li key={index}>
                    <Field
                        type="radio"
                        name="orientation"
                        id={`orientation-${item}`}
                        component="input"
                        value={item}     
                        validate={required}                    
                    />
                    <label for={`orientation-${item}`}>
                        <span className="text-md lg:text-lg">{item}</span>
                    </label>                                
                </li>
                )
            })}                        
            </ul>
            <BuilderError name="orientation" />
        </section>
        )}
    </BuilderPageTransition>
  )
}

export default BuilderSize