import React from 'react'

const EditButton = ({ targetStep, gotoPage }) => {
    return (
      <button type="button" onClick={()=>{gotoPage(targetStep)}} className="hover:underline">Edit</button>
    )
  }
  
  const SummaryRow = ({ label, children, targetStep, gotoPage }) => {
    return (
      <li>
        <span>{label}</span>
        {children}
        <span><EditButton targetStep={targetStep} gotoPage={gotoPage} /></span>
    </li>
    )
  }

  
const BuilderSummaryContent = ({ values, gotoPage }) => {
  return (
    <ul className="cust_summary">
        {values.size && (
        <SummaryRow label="Size" targetStep={0} gotoPage={gotoPage}>
            <span>{values.size}{values.size === 'Custom' &&  (
              <>
              {` - `}
              {values.customWidth && values.customWidth}
              {` X `}
              {values.customLength && values.customLength}
              {` X `}
              {values.customHeight && values.customHeight}
              {` `}
              {values.customUnit && values.customUnit}
              </>
            )}</span>
        </SummaryRow>        
        )}          
        {values.closure && (
        <SummaryRow label="Closure" targetStep={1} gotoPage={gotoPage}>
            <span>{values.closure.length > 1 ? (
              values.closure.map((item, index) => {
                return (
                  <>{item}{(index  + 1) < values.closure.length ? ', ' : ''}</>
                )
              })
            ) : (
                <>{values.closure}</>
            )}</span>
        </SummaryRow>                    
        )}
        {values.material && (       
        <SummaryRow label="Material" targetStep={2} gotoPage={gotoPage}>
            <span>{values.material}{values.specialtySpec && ` - ${values.specialtySpec}`}</span>            
        </SummaryRow>                         
        )}              
        {values.materialType && (       
        <SummaryRow label="Material Type" targetStep={2} gotoPage={gotoPage}>
            <span>{values.materialType}</span>            
        </SummaryRow>                         
        )}              
        {values.laminate && (       
        <SummaryRow label="Laminate" targetStep={2} gotoPage={gotoPage}>
            <span>{values.laminate}</span>
        </SummaryRow>                         
        )}          
        {(values.colour || values.printing) && (            
        <SummaryRow label="Printing" targetStep={3} gotoPage={gotoPage}>
            <span>
            {values.printing && <div>
              {values.printing.length > 1 ? (
                values.printing.map((item, index) => {
                  return (
                    <>{item}{(index  + 1) < values.printing.length ? ', ' : ''}</>
                  )
                })
              ) : (
                <>{values.printing}</>
              )}
              </div>
            }
            {values.colour && <div>{values.colour}</div>}
            </span>
        </SummaryRow>                         
        )}          
        {values.colourItem && (            
        <SummaryRow label="Colour" targetStep={3} gotoPage={gotoPage}>
            <span className="flex">                  
                {values.colourItem.map((item) => (
                <p className="pr-2">{item}</p>
                ))}
            </span>
        </SummaryRow>                         
        )}          
        <SummaryRow label="Additions" targetStep={4} gotoPage={gotoPage}>
        <span>
            {values.embossing && <div>{values.embossing}</div>}
            {values.foil && values.foil !== 'None' && <div>{values.foil}</div>}
            {values.texture && values.texture !== 'None' && <div>{values.texture}</div>}
            {values.finish && values.finish !== 'None' && <div>{values.finish}</div>}
            {values.feature && values.feature !== 'None' && <div>{values.feature}</div>}
        </span>
        </SummaryRow>          
        {values.quantity && (     
        <SummaryRow label="Quantity" targetStep={5} gotoPage={gotoPage}>
            <span>{values.quantity} {values.quantityCustom && ` - ${values.quantityCustom}`}</span>
        </SummaryRow>                    
        )}          
        {values.dieline && (            
        <SummaryRow label="Dielines" targetStep={6} gotoPage={gotoPage}>
            <span>{values.dieline}</span>
        </SummaryRow>            
        )}          
    </ul>
  )
}

export default BuilderSummaryContent