import React, { useState } from 'react';
import { Field } from 'react-final-form'
import { motion } from 'framer-motion';
import { GatsbyImage } from "gatsby-plugin-image"

// Components
import { PortableTextBlock } from "../../components/ui/portableTextBlock"

const required = value => (value ? undefined : 'Required')

const BuilderMaterialValidate = ({ title, imagePrimary, description, circle = false, index, setHoverImage, setHoverDescription, name, showDesc = true }) => {
    const [showDescription, setShowDescription] = useState(false)    

    return (    
          <div 
              className="w-full relative"              
              >              
              <Field
                  type="radio"
                  name={name}
                  component="input"
                  id={`${title ? `${title.replace(/ /g, '-')}-${index}` : index}`}
                  value={title}   
                  validate={required}                                           
              />
              <label 
                  htmlFor={`${title ? `${title.replace(/ /g, '-')}-${index}` : index}`}
                  className="cursor-pointer"                    
                  >
              <div className="text-base group">
                      {imagePrimary && imagePrimary.asset ? (
                          <div 
                            className={`card-media`}
                            onMouseOver={() => setHoverImage(imagePrimary ? imagePrimary : null)} 
                            onMouseOut={() => setHoverImage(null)} 
                            >
                              <GatsbyImage          
                              image={imagePrimary.asset.gatsbyImageData} 
                              alt={imagePrimary.asset.alt} 
                              className={`w-full aspect-square object-cover img-rounded overflow-hidden ${circle ? 'rounded-full' : 'rounded-lg'}`}                
                              />    
                          </div>
                      ) : (
                          <div className={`card-media w-full aspect-square object-cover bg-pp-grey30 ${circle ? 'rounded-full' : 'rounded-lg'}`}></div>
                      )}
                      <div className={`mt-2 text-sm flex ${circle ? 'text-center' : ''}`}>
                        <span className="xl:hover:underline">{title && title}</span>
                        {showDesc && description && (
                            <span
                                className={`hidden xl:block ml-2 fill-white`}
                                onMouseOver={() => setHoverDescription(description ? description : null)} 
                                onMouseOut={() => setHoverDescription(null)} 
                            >
                                <svg 
                                    className={`cursor-help w-4 h-4 trans-std`} 
                                    xmlns="http://www.w3.org/2000/svg" 
                                    width="24" 
                                    height="24" 
                                    viewBox="0 0 24 24"
                                >
                                    <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm1 18h-2v-8h2v8zm-1-12.25c.69 0 1.25.56 1.25 1.25s-.56 1.25-1.25 1.25-1.25-.56-1.25-1.25.56-1.25 1.25-1.25z"/>
                                </svg>
                            </span>
                        )}
                        </div>
                      
                      <div className={`fixed top-1/2 left-1/2 w-4/5 transform -translate-1/2 max-w-xs bg-black p-4 lg:p-8 rounded-lg text-white z-20 ${showDescription ? 'hidden' : 'hidden'}`}>
                          <div
                          className="flex justify-between items-baseline text-sm hover:cursor-pointer"
                          aria-label="Show details"
                          role="button"
                          tabIndex="0"
                          onClick={() => setShowDescription(!showDescription)}
                          onKeyDown={() => setShowDescription(!showDescription)}
                          >
                              
                              {description && <span className="opacity-40 hover:opacity-100 text-white">Close</span>}
                          </div>                            
                          {description && (
                              <motion.div        
                              initial={{ opacity: '0', height: '0px'}}                                            
                              animate={{ opacity: showDescription ? '100%' : '0', height: showDescription ? 'auto' : '0px' }}                    
                              >
                              <PortableTextBlock className="py-2 material-desc" text={description} />
                              </motion.div>
                          )}
                      </div>
                  </div> 
              </label>    
              {showDesc && description && (
                    <div
                        className={`hidden mt-2 fill-white`}
                        onMouseOver={() => setHoverDescription(description ? description : null)} 
                        onMouseOut={() => setHoverDescription(null)} 
                    >
                        <svg 
                            className={`cursor-help w-4 h-4 trans-std`} 
                            xmlns="http://www.w3.org/2000/svg" 
                            width="24" 
                            height="24" 
                            viewBox="0 0 24 24"
                        >
                            <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm1 18h-2v-8h2v8zm-1-12.25c.69 0 1.25.56 1.25 1.25s-.56 1.25-1.25 1.25-1.25-.56-1.25-1.25.56-1.25 1.25-1.25z"/>
                        </svg>
                    </div>
                )}                            
          </div>        
    )
}

export default BuilderMaterialValidate