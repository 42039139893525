import React from 'react'
import { Link } from "gatsby"

const BuilderFooter = ({ 
    previous, 
    submitting, 
    fromPrice, 
    showPrice,
    prodPrice, 
    cartProject, 
    isLastPage,
    slug, 
    page,
    title,
    moq
}) => {
  return (
    <div className="cust_footer">
        <div className="title">
        
            {cartProject && <Link to={`/cart`}  className="mr-2 hover:underline">{cartProject}</Link>}             
            <Link to={`/packaging/${slug.current}`} className="hover:underline">{title && title}</Link>
        
        </div>              
        
        <div className="pricing">
        
            <span className="text-pp-grey70">{moq && 'MOQ'}</span>
            <span className="2xl:text-md">{moq && moq}</span>
        
        </div>
        
        <div className="quantity">
        {
            !!showPrice && 
            <>
                <span className="text-pp-grey70">From</span>
                <span className="2xl:text-md">${fromPrice} per unit</span>
            </>
        }
            

        </div>        
        
        <div className="navigation">                
            
            <button 
                type="button"
                className={`navigate-prev mr-2 ${page < 1 ? 'pointer-events-none ' : ''}`}
                onClick={previous}
                >Previous
            </button>
        
            {!isLastPage && (
                <button 
                className="navigate-nxt"              
                type="submit"
                >Next</button>
            )}    
        
            {isLastPage && (
                <button className="navigate-nxt" type="submit" disabled={submitting}>
                Add to my project
                </button>
            )}                    
        
        </div> 

    </div>     
  )
}

export default BuilderFooter