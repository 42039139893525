import React from 'react'
import { Field } from 'react-final-form'

const BuilderError = ({ name }) => {
  return (
    <Field
    name={name}
    subscription={{ touched: true, error: true }}
    render={({ meta: { touched, error } }) =>
      touched && error ? <span className="error text-red-500">{error}</span> : null
    }
  />
  )
}

export default BuilderError